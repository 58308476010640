import { initFancybox } from "@/other/fancybox.ts";

import "./review.css";
import "./review-widget/reviewScore.css";

function initReview() {
  initFancybox();
}

export { initReview };
